<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="mconForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('mconForm')"/>
        <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="mconForm" :rules="rules" :model="mconForm" :disabled="isShow" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="单据编号" prop="mcon_no" >
            <el-input disabled v-model="mconForm.mcon_no" maxlength="30" show-word-limit placeholder="请填写单据编号">
            </el-input>
          </el-form-item>
          <el-form-item label="交货时间" prop="mcon_date">
            <el-date-picker
              :disabled="flag"
              v-model="mconForm.mcon_date"
              type="date"
              placeholder="请选择交货时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购方" prop="acct_fctr_id" >
            <el-input disabled v-model="mconForm.acct_fctr_name" show-word-limit placeholder="请选择合作单位"></el-input>
            <!-- <el-select v-model="mconForm.acct_fctr_name" placeholder="请选择账套工厂" clearable @visible-change="getAcctFctrId($event)">
              <el-option
                  v-for="item in acctFctrGroupOpt"
                  :key="item.acct_fctr_id"
                  :label="item.acct_fctr_name"
                  :value="item.acct_fctr_id">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="总金额" prop="mcon_total">
            <el-input :disabled="flag" v-model="mconForm.mcon_total" maxlength="15" @input ="mconForm.mcon_total = helper.keepTNum1(mconForm.mcon_total)" @blur ="mconForm.mcon_total = helper.retain(mconForm.mcon_total,3)" show-word-limit placeholder="请填写总金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售方" prop="cptt_id" >
            <el-input disabled v-model="mconForm.tg_acct_fctr_name" show-word-limit placeholder="请选择合作单位"></el-input>
            <!-- <el-select v-model="mconForm.tg_acct_fctr_name" placeholder="请选择合作单位" clearable @visible-change="getCpttId($event)">
              <el-option
                  v-for="item in cpttGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="备注" prop="mcon_remark">
            <el-input :disabled="flag" v-model="mconForm.mcon_remark" placeholder="请填写备注"  type="textarea" :rows="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button"></div>
      <el-row class="vd_mrt">
        <MconAddMtrl :flag="flag" :mtrlForm="mconForm"></MconAddMtrl>
      </el-row>
      <!-- <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <MconAddEditIn @confirmIn="confirmIn" ref="MconAddEditIn"></MconAddEditIn>
      </el-dialog> -->
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="mconForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import editHeader from "@/views/component/editHeader";
import inputUser from "@/views/component/inputUser";
// import MconAddEditIn from "@/views/ContractManagement/MconManage/TabChild/Componet/MconAddEditIn";
import MconAddMtrl from "@/views/ContractManagement/MconManage/TabChild/Componet/MconAddMtrl";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle";
import {mconAPI} from "@api/modules/mcon";
export default {
  name: "MconEditMain",
  components:{
    editHeader,
    inputUser,
    // MconAddEditIn,
    MconAddMtrl,
  },
  data(){
    return{
      mconForm:{},
      rules:{
        mcon_total:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
      },
      acctFctrGroupOpt:[],
      cpttGroupOpt:[],
      remove_mcon_mtrl_li:[],
      mcon_mtrl_li:[],
      notice:false,
      isShow: true,
      btn:{},
      stffForm:{},
      title:'',
      flag:false,
      loadingFlag:true
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      if(this.$route.query.form_id){
        this.loadingFlag = true
        this.getMconInfo();
      }
    },
    //启用编辑
    openEdit(){
      this.isShow = false;
      this.$emit('isShow',this.isShow)
    },
    //撤销编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.initData()
        this.isShow = true
        this.$emit('isShow',this.isShow)
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(()=>{})
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      this.mconForm.mcon_date = (new Date(this.mconForm.mcon_date)).getTime() / 1000;
      let mconForm = {};
      mconForm = Object.assign(mconForm,this.mconForm);
      post(mconAPI.editMcon,mconForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              this.isShow = true;
              this.$emit('isShow',this.isShow)
              this.initData()
            } else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'单据编号重复'
              })
              this.mconForm.mcon_date = (new Date(this.mconForm.mcon_date)).getTime() * 1000;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.mconForm.mcon_date = (new Date(this.mconForm.mcon_date)).getTime() * 1000;
              this.initData()
            }
          })
          .catch((res)=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    // 刷新按钮
    buttonRefresh(){
      this.initData()
    },
    // 获取账套工厂id
    getAcctFctrId(flag){
      if(flag === true && this.acctFctrGroupOpt.length === 0){
        get(acctAPI.getAcctFctrs)
        .then(res=>{
          if(res.data.code === 0){
            this.acctFctrGroupOpt = res.data.data.list
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          }
        })
        .catch((res)=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        })
      }
    },
    // 获取合作单位id
    getCpttId(flag){
      if(flag === true && this.cpttGroupOpt.length === 0){
        get(cpttAPI.getAllCpttsV1)
            .then(res=>{
              if(res.data.code === 0){
                this.cpttGroupOpt = res.data.data
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message: mg, type: tp});
              }
            })
            .catch((res)=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            })
      }
    },
    //获取表单信息
    getMconInfo(){
      let mcon_id = this.$route.query.form_id
      get(mconAPI.getMconById,{mcon_id:mcon_id})
          .then(res=>{
            if (res.data.code === 0) {
              this.mconForm = res.data.data.form;
              this.mconForm.mcon_date = this.mconForm.mcon_date*1000;
              this.btn = res.data.data.btn;

              // 生成按钮状态
              if(this.mconForm.mcon_type === 1){
                this.flag = true;
              }
              this.stffForm.stff_name = this.mconForm.stff_name;
              this.stffForm.dept_name = this.mconForm.dept_name;
              this.stffForm.dept_team_name = this.mconForm.dept_team_name;
              this.stffForm.user_id = this.mconForm.user_id;
              this.stffForm.dept_id = this.mconForm.dept_id;
              this.stffForm.stff_id = this.mconForm.stff_id;
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch((res)=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mconForm.mcon_mtrl_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
.vd_edhea{
  position: relative;
}
.vd_export{
  position: absolute;
  top: 0;
  left: 65px;
}
</style>