<template>
  <div>
    <el-table ref="multiTable"  :data="mtrlForm.mcon_mtrl_list" border >
      <!-- <el-table-column type="selection" width="48" align="center"></el-table-column> -->
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index+1 }}
        </template>
      </el-table-column>
      <el-table-column label="物料编号">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_no" maxlength="30" show-word-limit placeholder="暂无物料编号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="物料名称">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_name" maxlength="30" show-word-limit placeholder="暂无物料名称"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_type'" :rules="[{required:true}]">
            <el-select :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_type" placeholder="请选择物料类型">
              <el-option
                  v-for="item in mtrlTypeGroupOpt"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="规格">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_spec'">
            <el-tooltip :content="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_spec" placement="top" v-if="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_spec">
              <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_spec" maxlength="50" show-word-limit placeholder="暂无规格"></el-input>
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="颜色">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_color'">
            <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_color" maxlength="10" show-word-limit placeholder="暂无颜色"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="克重">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_weight'">
            <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_weight" show-word-limit placeholder="暂无克重"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="门幅">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_width'">
            <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_width" show-word-limit placeholder="暂无门幅"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单位">
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mtrl_unit'" :rules="[{required:true}]">
            <el-input :disabled="true" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mtrl_unit" show-word-limit placeholder="暂无单位"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vd_title">数量</span> 
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mcon_mtrl_num'" :rules="[{required:true}]">
            <el-input :disabled="flag" @change="totalAmount"  @blur ="mtrlForm.mcon_mtrl_list[scope.$index].mcon_mtrl_num = helper.retain(mtrlForm.mcon_mtrl_list[scope.$index].mcon_mtrl_num,2)" show-word-limit v-model="mtrlForm.mcon_mtrl_list[scope.$index].mcon_mtrl_num" placeholder="暂无数量"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="vd_title">价格</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'mcon_mtrl_list.'+scope.$index+'.mcon_mtrl_price'" :rules="[{required:true}]">
            <el-input :disabled="flag" @change="totalAmount" @blur ="mtrlForm.mcon_mtrl_list[scope.$index].mcon_mtrl_price = helper.retain(mtrlForm.mcon_mtrl_list[scope.$index].mcon_mtrl_price,2)" v-model="mtrlForm.mcon_mtrl_list[scope.$index].mcon_mtrl_price" show-word-limit placeholder="暂无价格"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import options from "@/views/component/common/options";

export default {
  name: "MconAddMtrl",
  props:{
    mtrlForm:{
      type: Object,
      required:true,
    },
    flag:{
      type: Boolean,
      required:true,
    }
  },
  data(){
    return {
      selectionsList:[],
      mtrlTypeGroupOpt:[],
    }
  },
  created(){
    this.mtrlTypeGroupOpt = options.mtrlUnit;
  },
  methods:{
    // 清空
    clear(){
      this.$refs.multiTable.clearSelection();
      this.multiSelection = null
    },
    // //物料信息选择框
    // handleSelectionChange(selection) {
    //   this.selectionsList = [];
    //   this.selectionsList = selection
    //   this.$emit("handleSelectionChange",this.selectionsList)
    // },
    // 计算总金额
    totalAmount(){
      let temp = 0;
      if(this.mtrlForm.mcon_mtrl_list.length>0){
        for(let i=0;i<this.mtrlForm.mcon_mtrl_list.length;i++){
          if(this.mtrlForm.mcon_mtrl_list[i].mcon_mtrl_num!==undefined && this.mtrlForm.mcon_mtrl_list[i].mcon_mtrl_price!==undefined){
            temp = temp + parseInt(this.mtrlForm.mcon_mtrl_list[i].mcon_mtrl_num)*parseInt(this.mtrlForm.mcon_mtrl_list[i].mcon_mtrl_price)
          }
        }
        this.mtrlForm.mcon_total = temp;
      }  
    }
  }
}
</script>

<style scoped lang="scss">
.vd_title{
  color: $color-deep-red;
}
</style>