<template>
  <div class="vg_wrapper">
    <el-tabs
        v-model="activeName"
        type="border-card"
    >
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow"/>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 :isShow="isSho"/>
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MconEditMain from "@/views/ContractManagement/MconManage/TabChild/MconEditMain";
import MconEditAppr from "@/views/ContractManagement/MconManage/TabChild/MconEditAppr";
import MconEditAbout from "@/views/ContractManagement/MconManage/TabChild/MconEditAbout";

export default {
  name: "MconEdit",
  components: {
    childTab1: MconEditMain,
    childTab2: MconEditAppr,
    childTab3: MconEditAbout
  },
  data() {
    return {
      activeName: "first",
      isSho:true,
    }
  },
  methods: {
    isShow(val){
      this.isSho = val;
    }
  }
}
</script>

<style scoped>

</style>